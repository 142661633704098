import "core-js/modules/es.array.push.js";
import { defineComponent } from 'vue';
import { onboardingStore } from '@/state/onboardingStore';
import { isDev, rules, RequestError } from '../../../common-ui';
import { getProfile, login } from '../services/data';
import { demoUserEmail } from '../../../types';
import { StepId } from '@/state/tasks';
export default defineComponent({
  components: {},
  data: () => ({
    fullState: onboardingStore.state,
    roseAccount: {
      email: '',
      password: ''
    },
    clientIds: [],
    rules: rules,
    loading: false,
    loginError: null,
    praxis: null
  }),
  computed: {},
  mounted() {
    if (isDev) {
      this.roseAccount.email = demoUserEmail;
      this.roseAccount.password = 'demo';
    }
  },
  methods: {
    async login() {
      try {
        this.loading = true;
        this.loginError = null;
        const res = await login(this.roseAccount);
        this.roseAccount.token = res.token;
        const cids = [];
        for (const cid in res.clients) {
          if (Object.prototype.hasOwnProperty.call(res.clients, cid)) {
            const cn = res.clients[cid];
            cids.push({
              name: cn.praxisKurzName,
              cid
            });
          }
        }
        this.clientIds = cids;
        if (this.clientIds.length === 1) {
          this.roseAccount.cid = this.clientIds[0].cid;
          this.praxis = this.clientIds[0].name;
        }
        console.log('login result', res);
      } catch (err) {
        if (err instanceof RequestError) {
          this.loginError = err.data.message;
        }
        console.log('err', err, err.data);
      } finally {
        this.loading = false;
      }
    },
    resetLogin() {
      this.roseAccount.email = undefined;
      this.roseAccount.password = undefined;
      this.roseAccount.cid = undefined;
      this.roseAccount.token = undefined;
      this.clientIds = [];
      this.loginError = null;
    },
    nextNoRoseAccount() {
      this.$emit('viewValidUpdate', true);
      onboardingStore.commit.setRoseAccount(null);
      onboardingStore.commit.setSkipStepByStepId({
        stepId: StepId.praxisdaten,
        skip: false
      });
      onboardingStore.commit.setSkipStepByStepId({
        stepId: StepId.admin,
        skip: false
      });
      onboardingStore.commit.setSkipStepByStepId({
        stepId: StepId.zahlungsdaten,
        skip: false
      });
      this.$emit('next');
    },
    async nextWithRoseAccount() {
      var _a, _b;
      if (!((_a = this.roseAccount) === null || _a === void 0 ? void 0 : _a.cid) || !((_b = this.roseAccount) === null || _b === void 0 ? void 0 : _b.token)) {
        return;
      }
      console.log(`get profile for cid: ${this.roseAccount.cid}`, this.roseAccount);
      let profile;
      try {
        profile = await getProfile(this.roseAccount.cid, this.roseAccount.token);
        console.log(`got profile for cid: ${this.roseAccount.cid}`, profile);
      } catch (e) {
        console.error(e);
        alert('Ihr Benutzerkonto konnte nicht abgefragt werden.');
        return;
      }
      if (!profile.onboardingDaten) {
        alert('Ihr rose account kann nicht verwendet werden. Bitten wenden Sie sich an den Support!');
        return;
      }
      if (profile.onboardingDaten.praxisDaten) {
        onboardingStore.commit.setPraxisdaten(profile.onboardingDaten.praxisDaten);
      }
      onboardingStore.commit.setRoseAccount(this.roseAccount);
      onboardingStore.commit.setSkipStepByStepId({
        stepId: StepId.praxisdaten,
        skip: true
      });
      onboardingStore.commit.setSkipStepByStepId({
        stepId: StepId.admin,
        skip: true
      });
      onboardingStore.commit.setSkipStepByStepId({
        stepId: StepId.zahlungsdaten,
        skip: true
      });
      this.$emit('viewValidUpdate', true);
      this.$emit('next');
    }
  }
});