import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "wrap"
  }, [_c('div', {
    staticClass: "card-wrap"
  }, [_vm.stepIsInFlow(_vm.StepId.r4cPaketAuswahl) ? _c(VCard, {
    staticClass: "card",
    attrs: {
      "elevation": "4"
    }
  }, [_c(VCardTitle, [_vm._v("Pakete")]), _c(VCardText, [_c('div', {
    staticClass: "paket-changes"
  }, [_vm._l(_vm.paketeR4cToShow, function (app) {
    return [_c('div', {
      staticClass: "app",
      attrs: {
        "id": app.id
      }
    }, [_c('div', {
      staticClass: "name"
    }, [_vm._v(_vm._s(app.name))]), _vm.changeInfo[app.id].typ === "included" ? _c('div', {
      staticClass: "included"
    }, [_c(VIcon, {
      staticClass: "typ-icon"
    }, [_vm._v("fas fa-cart-plus")]), _c('div', {
      staticClass: "typ-name"
    }, [_vm._v("Enthalten in")]), _c('div', {
      staticClass: "change-info"
    }, [_c('div', {
      staticClass: "alt"
    }, [_c('div', {
      staticClass: "change-title"
    }, _vm._l(_vm.changeInfo[app.id].includeInfo.whereNames, function (w) {
      return _c('span', {
        staticClass: "change-title-name"
      }, [_vm._v(_vm._s(w))]);
    }), 0), _c('div', {
      staticClass: "prices"
    }, [_vm.getChoiceInfo(_vm.changeInfo[app.id]?.neu)?.price >= 0 ? _c('div', {
      staticClass: "price"
    }, [_c('div', {
      staticClass: "price-value strike-through"
    }, [_vm._v(_vm._s(_vm._f("euro")(_vm.getChoiceInfo(_vm.changeInfo[app.id]?.neu)?.price)))]), _c('div', {
      staticClass: "price-suffix"
    }, [_vm._v("zzgl. MwSt. / Monat")])]) : _vm._e()])])])], 1) : _vm.changeInfo[app.id].typ === "unchanged" ? _c('div', {
      staticClass: "unchanged"
    }, [_c(VIcon, {
      staticClass: "typ-icon"
    }, [_vm._v("fas fa-equals")]), _c('div', {
      staticClass: "typ-name"
    }, [_vm._v("Unverändert")]), _c('div', {
      staticClass: "change-info"
    }, [_c('div', {
      staticClass: "alt"
    }, [_c('div', {
      staticClass: "change-title"
    }, [_c('span', [_vm._v("Bleibt:")]), _c('span', {
      staticClass: "change-title-name"
    }, [_vm._v(_vm._s(_vm.getChoiceInfo(_vm.changeInfo[app.id].alt)?.name || 'Deaktiviert'))])])])])], 1) : _vm.changeInfo[app.id].typ === "cancel" ? _c('div', {
      staticClass: "cancel"
    }, [_c(VIcon, {
      staticClass: "typ-icon"
    }, [_vm._v("fas fa-ban")]), _c('div', {
      staticClass: "typ-name"
    }, [_vm._v("Kündigung")]), _c('div', {
      staticClass: "change-info"
    }, [_c('div', {
      staticClass: "alt"
    }, [_c('div', {
      staticClass: "change-title"
    }, [_c('span', [_vm._v("Bisher:")]), _c('span', {
      staticClass: "change-title-name"
    }, [_vm._v(_vm._s(_vm.getChoiceInfo(_vm.changeInfo[app.id].alt)?.name || 'Deaktiviert'))])])])])], 1) : _vm.changeInfo[app.id].typ === "upgrade" || _vm.changeInfo[app.id].typ === "downgrade" ? _c('div', {
      staticClass: "upgrade-downgrade"
    }, [_vm.changeInfo[app.id].typ === "upgrade" ? _c(VIcon, {
      staticClass: "typ-icon"
    }, [_vm._v("fas fa-up-right")]) : _vm.changeInfo[app.id].typ === "downgrade" ? _c(VIcon, {
      staticClass: "typ-icon"
    }, [_vm._v("fas fa-down-right")]) : _vm._e(), _c('div', {
      staticClass: "typ-name"
    }, [_vm._v("Upgrade")]), _c('div', {
      staticClass: "change-info"
    }, [_c('div', {
      staticClass: "alt"
    }, [_c('div', {
      staticClass: "change-title"
    }, [_c('span', [_vm._v("Bisher:")]), _c('span', {
      staticClass: "change-title-name"
    }, [_vm._v(_vm._s(_vm.getChoiceInfo(_vm.changeInfo[app.id].alt)?.name || 'Deaktiviert'))])]), _c('div', {
      staticClass: "prices"
    }, [_vm.getChoiceInfo(_vm.changeInfo[app.id].alt)?.price >= 0 ? _c('div', {
      staticClass: "price"
    }, [_c('div', {
      staticClass: "price-value"
    }, [_vm._v(_vm._s(_vm._f("euro")(_vm.getChoiceInfo(_vm.changeInfo[app.id].alt)?.price)))]), _c('div', {
      staticClass: "price-suffix"
    }, [_vm._v("zzgl. MwSt. / Monat")])]) : _vm._e(), _vm.getChoiceInfo(_vm.changeInfo[app.id].alt)?.itemPrice >= 0 ? _c('div', {
      staticClass: "price-per-item"
    }, [_c('div', {
      staticClass: "price-value"
    }, [_vm._v(_vm._s(_vm._f("euro")(_vm.getChoiceInfo(_vm.changeInfo[app.id].alt)?.itemPrice, true)))]), _c('div', {
      staticClass: "price-suffix"
    }, [_vm._v("zzgl. MwSt. / " + _vm._s(_vm.getChoiceInfo(_vm.changeInfo[app.id].alt)?.itemName))])]) : _vm._e()])]), _c('div', {
      staticClass: "line"
    }), _c('div', {
      staticClass: "neu"
    }, [_c('div', {
      staticClass: "change-title"
    }, [_c('span', [_vm._v("Jetzt:")]), _c('span', {
      staticClass: "change-title-name"
    }, [_vm._v(_vm._s(_vm.getChoiceInfo(_vm.changeInfo[app.id].neu)?.name || 'Deaktiviert'))])]), _c('div', {
      staticClass: "prices"
    }, [_vm.getChoiceInfo(_vm.changeInfo[app.id].neu)?.price >= 0 ? _c('div', {
      staticClass: "price"
    }, [_c('div', {
      staticClass: "price-value"
    }, [_vm._v(_vm._s(_vm._f("euro")(_vm.getChoiceInfo(_vm.changeInfo[app.id].neu)?.price)))]), _c('div', {
      staticClass: "price-suffix"
    }, [_vm._v("zzgl. MwSt. / Monat")])]) : _vm._e(), _vm.getChoiceInfo(_vm.changeInfo[app.id].neu)?.itemPrice >= 0 ? _c('div', {
      staticClass: "price-per-item"
    }, [_c('div', {
      staticClass: "price-value"
    }, [_vm._v(_vm._s(_vm._f("euro")(_vm.getChoiceInfo(_vm.changeInfo[app.id].neu)?.itemPrice, true)))]), _c('div', {
      staticClass: "price-suffix"
    }, [_vm._v("zzgl. MwSt. / " + _vm._s(_vm.getChoiceInfo(_vm.changeInfo[app.id].neu)?.itemName))])]) : _vm._e(), app.id == _vm.R4CAPP.FACTORING && _vm.rzGutscheinInfo ? _c('div', {
      staticClass: "gutschein-preis-info"
    }, [_vm._v("Gutschein über " + _vm._s(_vm._f("euro")(_vm.rzGutscheinInfo.info.prozentualerkundenerlass / 100 * _vm.fullState.r4cPaket.price)) + " / Monat für " + _vm._s(_vm.rzGutscheinInfo.info.monatelaufzeit) + " Monate")]) : _vm._e()])])])], 1) : _c('div', {
      staticClass: "unknown"
    }, [_vm._v("Ööööö komisch: " + _vm._s(app.id) + ", " + _vm._s(_vm.changeInfo[app.id].typ))])])];
  })], 2)]), _c(VCardActions, [_c('div', {
    staticClass: "flex-grow-1"
  }), _c('CheckEditButton', {
    attrs: {
      "stepId": _vm.StepId.r4cPaketAuswahl
    }
  })], 1)], 1) : _vm._e(), _vm.rechenzentrenToDelete && _vm.rechenzentrenToDelete.length ? _c(VCard, {
    staticClass: "card",
    attrs: {
      "elevation": "4"
    }
  }, [_c(VCardTitle, [_c('span', [_vm._v("Rechenzentren werden")]), _c('span', {
    staticClass: "delete"
  }, [_vm._v(" entfernt")])]), _c(VCardText, [_vm._l(_vm.rechenzentrenToDelete, function (rzToDelete) {
    return [_c('div', {
      staticClass: "rz mt-4"
    }, [_c('h5', [_vm._v(_vm._s(rzToDelete.rzkey))]), _c('div', {
      staticClass: "keyValueList"
    }, [_c('div', {
      staticClass: "keyValueEntry"
    }, [_c('div', {
      staticClass: "key"
    }, [_vm._v("Hinzugefügt am")]), _c('div', {
      staticClass: "value"
    }, [_vm._v(_vm._s(_vm._f("date")(rzToDelete.createdAt)))])])])])];
  })], 2), _c(VCardActions, [_c('div', {
    staticClass: "flex-grow-1"
  }), _c('CheckEditButton', {
    attrs: {
      "stepId": _vm.StepId.r4cRechenzentren
    }
  })], 1)], 1) : _vm._e(), _vm.selectedAtleastOneRz ? _c(VCard, {
    staticClass: "card",
    attrs: {
      "elevation": "4"
    }
  }, [_c(VCardTitle, [_vm._v("Rechenzentrum"), _vm.rechenzentrenToDelete ? [_c('span', [_vm._v(" wird")]), _c('span', {
    staticClass: "add"
  }, [_vm._v(" hinzugefügt")])] : _vm._e()], 2), _c(VCardText, [_vm.rechenzentrenData.bfs ? _c('div', {
    staticClass: "rz mt-4"
  }, [_c('h5', [_vm._v("BFS")]), _c('div', {
    staticClass: "keyValueList"
  }, [_c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("Benutzer")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.rechenzentrenData.bfs.benutzer))])])]), _c('GutscheinInfo', {
    attrs: {
      "gutschein-info": _vm.gutscheinInfo.bfs
    }
  })], 1) : _vm._e(), _vm.rechenzentrenData.za ? _c('div', {
    staticClass: "rz mt-4"
  }, [_c('h5', [_vm._v("ZA")]), _c('div', {
    staticClass: "keyValueList"
  }, [_c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("Praxisnummer")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.rechenzentrenData.za.customerNumber))])]), _c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("Benutzername")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.rechenzentrenData.za.username))])])]), _c('GutscheinInfo', {
    attrs: {
      "gutschein-info": _vm.gutscheinInfo.za
    }
  })], 1) : _vm._e(), _vm.rechenzentrenData.dzr ? _c('div', {
    staticClass: "rz mt-4"
  }, [_c('h5', [_vm._v("DZR")]), _c('div', {
    staticClass: "keyValueList"
  }, [_c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("Organisationseinheit")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.dzrOrgUnitResolve[_vm.rechenzentrenData.dzr.org_unit]))])]), _c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("Kunden-/Vertragsnummer")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.rechenzentrenData.dzr.contract_number))])]), _c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("Benutzer")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.rechenzentrenData.dzr.username))])]), _c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("Passwort")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.obfuscatePassword(_vm.rechenzentrenData.dzr.password)))])])]), _c('GutscheinInfo', {
    attrs: {
      "gutschein-info": _vm.gutscheinInfo.dzr
    }
  })], 1) : _vm._e(), _vm.rechenzentrenData.abz ? _c('div', {
    staticClass: "rz mt-4"
  }, [_c('h5', [_vm._v("ABZ")]), _c('div', {
    staticClass: "keyValueList"
  }, [_c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("Kunden-/Vertragsnummer")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.rechenzentrenData.abz.contract_number))])]), _c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("Benutzer")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.rechenzentrenData.abz.username))])]), _c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("Passwort")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.obfuscatePassword(_vm.rechenzentrenData.abz.password)))])])]), _c('GutscheinInfo', {
    attrs: {
      "gutschein-info": _vm.gutscheinInfo.abz
    }
  })], 1) : _vm._e(), _vm.rechenzentrenData.health ? _c('div', {
    staticClass: "rz mt-4"
  }, [_c('h5', [_vm._v("Health")]), _c('div', {
    staticClass: "keyValueList"
  }, [_c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("ID")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.rechenzentrenData.health.customerNumber))])]), _c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("Api-Key")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.rechenzentrenData.health.apiKey))])])]), _c('GutscheinInfo', {
    attrs: {
      "gutschein-info": _vm.gutscheinInfo.health
    }
  })], 1) : _vm._e(), _vm.rechenzentrenData.pvsreiss ? _c('div', {
    staticClass: "rz mt-4"
  }, [_c('h5', [_vm._v("PVS-Reiss")]), _c('div', {
    staticClass: "keyValueList"
  }, [_c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("Benutzername")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.rechenzentrenData.pvsreiss.username))])]), _c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("Passwort")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.obfuscatePassword(_vm.rechenzentrenData.pvsreiss.password)))])])]), _c('GutscheinInfo', {
    attrs: {
      "gutschein-info": _vm.gutscheinInfo.pvsreiss
    }
  })], 1) : _vm._e(), _vm.rechenzentrenData.arc ? _c('div', {
    staticClass: "rz mt-4"
  }, [_c('h5', [_vm._v("ARC")]), _c('div', {
    staticClass: "keyValueList"
  }, [_c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("Kundennummer")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.rechenzentrenData.arc.kunde))])]), _c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("Hash")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.rechenzentrenData.arc.hash))])])]), _c('GutscheinInfo', {
    attrs: {
      "gutschein-info": _vm.gutscheinInfo.arc
    }
  })], 1) : _vm._e(), _vm.rechenzentrenData.teamfaktor ? _c('div', {
    staticClass: "rz mt-4"
  }, [_c('h5', [_vm._v("Teamfaktor")]), _c('div', {
    staticClass: "keyValueList"
  }, [_c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("Benutzer")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.rechenzentrenData.teamfaktor.benutzer))])]), _c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("Passwort")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.obfuscatePassword(_vm.rechenzentrenData.teamfaktor.passwort)))])])]), _c('GutscheinInfo', {
    attrs: {
      "gutschein-info": _vm.gutscheinInfo.teamfaktor
    }
  })], 1) : _vm._e(), _vm.rechenzentrenData.mediserv ? _c('div', {
    staticClass: "rz mt-4"
  }, [_c('h5', [_vm._v("mediserv")]), _c('div', {
    staticClass: "keyValueList"
  }, [_c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("Kunde")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.rechenzentrenData.mediserv.kunde))])])]), _c('GutscheinInfo', {
    attrs: {
      "gutschein-info": _vm.gutscheinInfo.mediserv
    }
  })], 1) : _vm._e(), _vm.rechenzentrenData.carecapital ? _c('div', {
    staticClass: "rz mt-4"
  }, [_c('h5', [_vm._v("CareCapital")]), _c('div', {
    staticClass: "keyValueList"
  }, [_c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("Benutzer / Kundennummer")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.rechenzentrenData.carecapital.username))])]), _c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("Passwort")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.obfuscatePassword(_vm.rechenzentrenData.carecapital.password)))])])]), _c('GutscheinInfo', {
    attrs: {
      "gutschein-info": _vm.gutscheinInfo.carecapital
    }
  })], 1) : _vm._e(), _vm.rechenzentrenData.zab ? _c('div', {
    staticClass: "rz mt-4"
  }, [_c('h5', [_vm._v("ZAB")]), _c('div', {
    staticClass: "keyValueList"
  }, [_c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("Benutzer")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.rechenzentrenData.zab.username))])]), _c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("Passwort")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.obfuscatePassword(_vm.rechenzentrenData.zab.password)))])])]), _c('GutscheinInfo', {
    attrs: {
      "gutschein-info": _vm.gutscheinInfo.zab
    }
  })], 1) : _vm._e(), _vm.rechenzentrenData.nelly ? _c('div', {
    staticClass: "rz mt-4"
  }, [_c('h5', [_vm._v("nelly")]), _c('div', {
    staticClass: "keyValueList"
  }, [_c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("Benutzer")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.rechenzentrenData.nelly.username))])]), _c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("Passwort")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.obfuscatePassword(_vm.rechenzentrenData.nelly.password)))])]), _c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("Organisations-ID")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.rechenzentrenData.nelly.organization_id))])]), _c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("Kundennummer")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.rechenzentrenData.nelly.customer_number))])])]), _c('GutscheinInfo', {
    attrs: {
      "gutschein-info": _vm.gutscheinInfo.nelly
    }
  })], 1) : _vm._e(), _vm.rechenzentrenData.fabius ? _c('div', {
    staticClass: "rz mt-4"
  }, [_c('h5', [_vm._v("fabius")]), _c('div', {
    staticClass: "keyValueList"
  }, [_c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("Key")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.rechenzentrenData.fabius.key))])])]), _c('GutscheinInfo', {
    attrs: {
      "gutschein-info": _vm.gutscheinInfo.fabius
    }
  })], 1) : _vm._e(), _vm.rechenzentrenData.test ? _c('div', {
    staticClass: "rz mt-4"
  }, [_c('h5', [_vm._v("TEST")]), _c('div', {
    staticClass: "keyValueList"
  }, [_c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("Benutzer")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.rechenzentrenData.test.user))])]), _c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("Passwort")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.obfuscatePassword(_vm.rechenzentrenData.test.password)))])])]), _c('GutscheinInfo', {
    attrs: {
      "gutschein-info": _vm.gutscheinInfo.test
    }
  })], 1) : _vm._e()]), _vm.additionalRzLicenceRequired ? _c(VCardText, [_c('p', [_vm._v("Da Sie einen weiteren Rechenzentren Anbieter hinzufügen wird eine weitere Lizenz benötigt")]), _c('h5', [_vm._v("Rechenzentren Zusatzlizenz")]), _c('div', {
    staticClass: "keyValueList"
  }, [_c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("Preis")]), _c('div', {
    staticClass: "value"
  }, [_vm._v("30 zzgl. MwSt. / Monat")])])])]) : _vm._e(), _c(VCardActions, [_c('div', {
    staticClass: "flex-grow-1"
  }), _c('CheckEditButton', {
    attrs: {
      "stepId": _vm.StepId.r4cRechenzentren
    }
  })], 1)], 1) : _vm._e(), _vm.stepIsInFlow(_vm.StepId.paket) ? _c(VCard, {
    staticClass: "card",
    attrs: {
      "elevation": "4"
    }
  }, [_c(VCardTitle, [_vm._v("Paket")]), _c(VCardText, [_vm.fullState.lizenzInfo ? _c('div', {
    staticClass: "keyValueList"
  }, [_c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("Paket")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.fullState.lizenzInfo.paket.name))])]), _c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("Gesamtpreis")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.gesamtPreis) + "€ " + _vm._s(_vm.fullState.lizenzInfo.paket.promonat))])]), _c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("Anzahl Behandler")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.fullState.lizenzInfo.behandlerCount))])]), _c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("Anzahl Prophylaxe")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.fullState.lizenzInfo.prophylaxeCount))])]), _c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("Einrichtungsgebühr")]), _vm.fullState.lizenzInfo.saveSetupFee ? _c('div', {
    staticClass: "value"
  }, [_vm._v("0 €")]) : _c('div', {
    staticClass: "value"
  }, [_vm._v("499 €")])]), _c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("Nächster Kündigungstermin")]), _vm.fullState.lizenzInfo.saveSetupFee ? _c('div', {
    staticClass: "value"
  }, [_vm._v("In 12 Monaten")]) : _c('div', {
    staticClass: "value"
  }, [_vm._v("sofort")])])]) : _vm._e()]), _c(VCardActions, [_c('div', {
    staticClass: "flex-grow-1"
  }), _c('CheckEditButton', {
    attrs: {
      "stepId": _vm.StepId.paket
    }
  })], 1)], 1) : _vm._e(), !_vm.usedRoseAccount && _vm.fullState.praxisDaten ? _c(VCard, {
    staticClass: "card",
    attrs: {
      "elevation": "4"
    }
  }, [_c(VCardTitle, [_vm._v("Auftraggeber")]), _c(VCardText, [_c('div', {
    staticClass: "keyValueList"
  }, [_c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("Name")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.fullState.praxisDaten.auftraggeber.vorname) + " " + _vm._s(_vm.fullState.praxisDaten.auftraggeber.name))])]), _c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("E-Mail")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.fullState.praxisDaten.auftraggeber.email))])]), _vm.fullState.praxisDaten.auftraggeber.straße ? _c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("Adresse")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.fullState.praxisDaten.auftraggeber.straße))])]) : _vm._e(), _vm.fullState.praxisDaten.auftraggeber.plz ? _c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("Ort")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.fullState.praxisDaten.auftraggeber.plz) + " " + _vm._s(_vm.fullState.praxisDaten.auftraggeber.stadt))])]) : _vm._e()])]), _c(VCardTitle, [_vm._v("Praxisdaten")]), _c(VCardText, [_c('div', {
    staticClass: "keyValueList"
  }, [_c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("Praxisname")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.fullState.praxisDaten.praxis.name))])]), _c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("Telefon")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.fullState.praxisDaten.praxis.tel))])]), _vm.fullState.praxisDaten.praxis.straße ? _c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("Adresse")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.fullState.praxisDaten.praxis.straße))])]) : _vm._e(), _vm.fullState.praxisDaten.praxis.plz || _vm.fullState.praxisDaten.praxis.stadt ? _c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("Ort")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.fullState.praxisDaten.praxis.plz) + " " + _vm._s(_vm.fullState.praxisDaten.praxis.stadt))])]) : _vm._e(), _vm.fullState.lizenzInfo ? _c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("PVS")]), _vm.fullState.lizenzInfo.pvs === "charly" ? _c('div', {
    staticClass: "value"
  }, [_vm._v("solutio charly")]) : _vm.fullState.lizenzInfo.pvs === "z1" ? _c('div', {
    staticClass: "value"
  }, [_vm._v("CGM Z1 Pro")]) : _vm._e()]) : _vm._e()])]), _c(VCardActions, [_c('div', {
    staticClass: "flex-grow-1"
  }), _c('CheckEditButton', {
    attrs: {
      "stepId": _vm.StepId.praxisdaten
    }
  })], 1)], 1) : _vm._e(), _vm.fullState.adminBenutzer ? _c(VCard, {
    staticClass: "card",
    attrs: {
      "elevation": "4"
    }
  }, [_c(VCardTitle, [_vm._v("Admin Benutzer")]), _c(VCardText, [_vm.fullState.adminBenutzer ? _c('div', {
    staticClass: "keyValueList"
  }, [_c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("Name")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.fullState.adminBenutzer.vorname) + " " + _vm._s(_vm.fullState.adminBenutzer.name))])]), _c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("E-Mail")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.fullState.adminBenutzer.email))])])]) : _vm._e()]), _c(VCardTitle, [_vm._v("rose Ansprechpartner")]), _c(VCardText, [_vm.fullState.adminBenutzer ? _c('div', {
    staticClass: "keyValueList"
  }, [_vm.fullState.technischerAnprechpartner && _vm.fullState.technischerAnprechpartner.name ? [_c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("Name")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.fullState.technischerAnprechpartner.vorname) + " " + _vm._s(_vm.fullState.technischerAnprechpartner.name))])]), _c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("E-Mail")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.fullState.technischerAnprechpartner.email))])]), _c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("Telefon")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.fullState.technischerAnprechpartner.tel))])])] : [_c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }), _c('div', {
    staticClass: "value"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("keine Angaben")])])])]], 2) : _vm._e()]), _c(VCardActions, [_c('div', {
    staticClass: "flex-grow-1"
  }), _c('CheckEditButton', {
    attrs: {
      "stepId": _vm.StepId.admin
    }
  })], 1)], 1) : _vm._e(), _vm.stepIsInFlow(_vm.StepId.zahlungsdaten) && _vm.fullState.zahlungsdaten ? _c(VCard, {
    staticClass: "card",
    attrs: {
      "elevation": "4"
    }
  }, [_c(VCardTitle, [_vm._v("Zahlungsdaten")]), _c(VCardText, [_vm.fullState.zahlungsdaten ? _c('div', {
    staticClass: "keyValueList"
  }, [_c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("Kontoinhaber")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.fullState.zahlungsdaten.kontoinhaber))])]), _c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("Kontoinhaber E-Mail")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.fullState.zahlungsdaten.kontoinhaberEmail))])])]) : _vm._e()]), _c(VCardActions, [_c('div', {
    staticClass: "flex-grow-1"
  }), _c('CheckEditButton', {
    attrs: {
      "stepId": _vm.StepId.zahlungsdaten
    }
  })], 1)], 1) : _vm._e(), _vm.stepIsInFlow(_vm.StepId.paket) ? _c(VCard, {
    staticClass: "card",
    attrs: {
      "elevation": "4"
    }
  }, [_c(VCardTitle, [_vm._v("Gutschein")]), _c(VCardText, [_vm.fullState.lizenzInfo ? _c('div', {
    staticClass: "keyValueList"
  }, [_c('div', {
    staticClass: "keyValueEntry"
  }, [_c('div', {
    staticClass: "key"
  }, [_vm._v("Gutscheincode")]), _c('div', {
    staticClass: "value"
  }, [_c(VTextField, {
    attrs: {
      "label": "Gutscheincode"
    },
    model: {
      value: _vm.fullState.lizenzInfo.gutscheinCode,
      callback: function ($$v) {
        _vm.$set(_vm.fullState.lizenzInfo, "gutscheinCode", $$v);
      },
      expression: "fullState.lizenzInfo.gutscheinCode"
    }
  })], 1)])]) : _vm._e()])], 1) : _vm._e(), _c(VCard, {
    staticClass: "card",
    attrs: {
      "elevation": "4"
    }
  }, [_c(VCardTitle, [_vm._v("Aktions-Code")]), _c(VCardText, [_c('span', {
    staticClass: "pa-2"
  }, [_vm._v("Gib hier deinen Aktions-Code ein.")]), _c(VTextField, {
    staticClass: "mt-2 mb-2",
    attrs: {
      "placeholder": "Code",
      "filled": "",
      "outlined": "",
      "hide-details": "auto",
      "hint": _vm.aktionscodeInfo
    },
    model: {
      value: _vm.aktionscode,
      callback: function ($$v) {
        _vm.aktionscode = $$v;
      },
      expression: "aktionscode"
    }
  })], 1)], 1), _c(VCard, {
    staticClass: "card",
    attrs: {
      "elevation": "4"
    }
  }, [_c(VCardTitle, [_vm._v("Anmerkung")]), _c(VCardText, [_c('span', {
    staticClass: "pa-2"
  }, [_vm._v("Möchtest du uns noch etwas mitteilen?")]), _c(VTextarea, {
    staticClass: "mt-2 mb-2",
    attrs: {
      "placeholder": "Kommentar",
      "filled": "",
      "outlined": ""
    },
    model: {
      value: _vm.kommentar,
      callback: function ($$v) {
        _vm.kommentar = $$v;
      },
      expression: "kommentar"
    }
  })], 1)], 1)], 1), _c('div', {
    staticClass: "buchen"
  }, [_c(VBtn, {
    staticClass: "ma-2",
    attrs: {
      "color": "primary",
      "loading": _vm.requestPending,
      "disabled": !_vm.onboardingStore.getters.allStepsComplete
    },
    on: {
      "click": _vm.buchenClicked
    }
  }, [_vm.flowContainsPurchase ? _c('span', [_vm._v("Kostenpflichtig buchen")]) : _c('span', [_vm._v("Speichern")])])], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };